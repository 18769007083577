import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  RefObject,
} from "react";
// import { useInView } from "react-intersection-observer";

import Banner from "../banner/banner";
import Page from "../page/page";
import { ReactComponent as ZoomInIcon } from "../../assets/icons/zoomIn.svg";
import { useLocation, useNavigate } from "react-router-dom";

import "./welcomePage.scss";
import classnames from "classnames";
import { useMediaQuery } from "@react-hook/media-query";

const WelcomePage = () => {
  const isPortrait = useMediaQuery("only screen and (orientation: portrait)");
  const containerRef = useRef<HTMLDivElement>(null);
  const [nextEventArraytext, setNextEventArraytext] = useState([
    "Ouverture de l'arène : ",
    "chargement en cours ...",
  ]);
  const [nextEventUrl, setNextEventUrl] = useState("");
  const [lastTwitchStreamUrl, setLastTwitchStreamUrl] = useState(
    "https://www.twitch.tv/shotamsekai",
  );

  const [focusedZone, setFocusedZone] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetch(
      `https://firestore.googleapis.com/v1/projects/shotam-b276d/databases/(default)/documents/general_information/main`,
    )
      .then((res) => {
        res.json().then((obj) => {
          let label = obj.fields.next_event.stringValue;
          let url = obj.fields.next_event_url.stringValue;
          setNextEventArraytext(["Ouverture de l'arène : ", label]);
          setNextEventUrl(url);
        });
      })
      .catch(() => {});
  }, []);

  const getArrayTexts = () => {
    return nextEventArraytext;
  };

  return (
    <Page style={{ paddingBottom: "0px" }}>
      {/* <div className="backPage"></div> */}
      {/*@ts-ignore*/}
      <div className={classnames("welcomeBigtext")}>
        {isPortrait ? (
          <>
            <span className="welcomeBigText2">L'arène de combat</span>
            <span className="welcomeBigText1">totalement</span>
            <div className="welcomeBigText3">DÉJANTÉE</div>
          </>
        ) : (
          <>
            <span className="welcomeBigText2">L'arène de combat</span>{" "}
            <span className="welcomeBigText1">totalement</span>
            <div className="welcomeBigText3">DÉJANTÉE</div>
          </>
        )}
      </div>
      <div className="firstPagePlaceholder" />
      <div className="sectionTwo">
        <div className="presenter">
          <div className="presenterSection">
            <div className="welcomeBigText4"> Des affrontements </div>
            <div className="welcomeBigText5"> en équipe </div>
          </div>
          <div className="presenterSection">
            <div className="welcomeBigText4"> Des superpouvoirs </div>
            <div className="welcomeBigText5"> cultes</div>
          </div>
          <div className="presenterSection">
            <div className="welcomeBigText4"> Un gameplay </div>
            <div className="welcomeBigText5"> sportif </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            className="bookingButton bookingButtonDisabled"
            // disabled={true}
            // onClick={() => window.open(lastTwitchStreamUrl)}
          >
            {">"} Ouverture prochaine à Paris {"<"}
          </button>
        </div>
      </div>
      <div className="sectionOne">
        <div className="videoAndBannersContainer">
          <div className="videoTitle">
            Notre Super Trailer ! <div className="arrowDown"></div>{" "}
          </div>
          <div className="videoWrapper">
            {isPortrait ? (
              <iframe
                width="100%"
                height="600"
                src="https://youtube.com/embed/1eGXG7dwy50"
                title="Shotam : Trailer"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
              ></iframe>
            ) : (
              <div className="trailerContainer">
                <iframe
                  width="500"
                  height="282"
                  src="https://www.youtube.com/embed/H3UycQBNCvk"
                  title="Shotam : Trailer"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
                ></iframe>
              </div>
            )}
          </div>
          {/* <div className="bannerWrappper">
            <button
              className="twitchButton"
              onClick={() => window.open(lastTwitchStreamUrl)}
            >
              Voir le Pilote sur Twitch
            </button>
            <button
              className="bookingButton"
              disabled={nextEventUrl === "" || true}
              onClick={() => window.open(nextEventUrl)}
            >
              {">"} Ouverture au public en Septembre !!! {"<"}
            </button>
          </div> */}
        </div>
      </div>
      <div className="bottomBanner">
        <button onClick={() => navigate("/contact")}>Contact</button>
        <button onClick={() => navigate("/privacy")}>
          Politique de confidentialité
        </button>
        <button onClick={() => navigate("/partners")}>
          Conventions passées
        </button>
      </div>
    </Page>
  );
};

export default WelcomePage;
